/* eslint-disable no-console */
import { get, merge } from "lodash"
import { i18n } from "next-i18next"
import {
  IInterceptorRequest,
  IInterceptorResponse,
  IInterceptorError
} from "../axios.interface"

export const requests: IInterceptorRequest[] = [
  (_config = {}) => {
    const config = { ..._config }
    config.params = merge(
      {},
      { lang: get(i18n, "language"), timestamp: new Date().getTime() },
      config.params
    )

    return config
  }
]

export const responses: IInterceptorResponse[] = []

export const errors: IInterceptorError[] = []
