import { get, set, isArray, isString } from "lodash"
import { AxiosError } from "axios"
import {
  IInterceptorRequest,
  IInterceptorResponse,
  IInterceptorError
} from "../axios.interface"

const ERROR_DEFAULT = "Network error"
const ERRORS = {
  413: "File size is too large"
}

export const requests: IInterceptorRequest[] = []

const handleLocalError = (error: AxiosError) => {
  const data = get(error, "response.data")
  if (!data || isString(data)) {
    const status = get(error, "response.status")
    const message = ERRORS[status] || ERROR_DEFAULT
    set(error, "response.data.status", "fail")
    set(error, "response.data.message", message)
  }
}

export const handleErrorMessage = (error: AxiosError) => {
  handleLocalError(error)
  const result = get(error, "response.data")
  const { data: { message = "", status = "" } = {} } = result

  // const { data: { message, status } = {} } = result

  let specialError = ""
  if (result.errors && Object.values(result.errors).length > 0) {
    const values: string[] = Object.values(result.errors)
    specialError = get(values, 0)
  }

  const _error = get(result, "errors.data", get(result, "message", ""))
  const finalError = isArray(_error) ? _error[0] : _error
  const finalMessage = isArray(message) ? message[0] : message
  const lastResult = {
    ...result,
    ...{
      message: specialError || finalError || finalMessage,
      status: status || get(result, "status")
    }
  }
  return lastResult
}

export const responses: IInterceptorResponse[] = [
  (response) => {
    return response
  }
]

export const errors: IInterceptorError[] = [
  (error) => {
    handleErrorMessage(error)
    return Promise.reject(error)
  }
]
