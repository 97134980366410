// import "regenerator-runtime/runtime"
// import "bootstrap/dist/css/bootstrap.min.css"

import "bootstrap/dist/css/bootstrap.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "animate.css/animate.min.css"
import "react-datepicker/dist/react-datepicker.css"
import "react-toastify/dist/ReactToastify.min.css"
import "@styles/globals.scss"

import "@helpers/axios/axios.config"

import { appWithTranslation } from "next-i18next"

// import Head from "next/head"
import { UtilCookies, UtilEnv } from "@fxce/fe-core"

import ThirdPartyJS from "@components/others/ThirdPartyJS"
import TakeTokenNoSSR from "@components/auth/TakeToken"
import ToastContainerNoSSR from "@components/others/ToastContainer"
import env from "@config/env"
import { wrapper } from "@redux/store.js"

const { APP_ENV, IS_PROD, API_URL, COOKIE_DOMAIN } = env

UtilCookies.setEnv({
  APP_ENV,
  COOKIE_DOMAIN
})

UtilEnv.setCustomEnv({
  ...env,
  ST_API_URL: API_URL
})
const MyApp = ({ Component, pageProps }) => {
  return (
    <>
      {IS_PROD && <ThirdPartyJS />}
      <Component {...pageProps} />
      <TakeTokenNoSSR></TakeTokenNoSSR>
      <ToastContainerNoSSR></ToastContainerNoSSR>
    </>
  )
}

// export function reportWebVitals(metric) {
// console.log(metric)
// }

export default wrapper.withRedux(appWithTranslation(MyApp))
