// REDUX ACTION TYPES
export const SET_USER = "SET_USER"
export const SET_AUTH_CODE = "SET_AUTH_CODE"
export const CLEAR_USER = "CLEAR_USER"

export const INIT_ASSET = "INIT_ASSET"

export const INIT_USER_WITH_TOKEN = "INIT_USER_WITH_TOKEN"
export const INIT_CONFIG_WITH_AUTH = "INIT_CONFIG_WITH_AUTH"
export const INIT_CONFIG_WITHOUT_AUTH = "INIT_CONFIG_WITHOUT_AUTH"

export const CONFIG = "CONFIG"
export const CONFIG_GAME = "CONFIG_GAME"

export const SET_ASSET = "SET_ASSET"
export const SET_LOG_OUT = "SET_LOG_OUT"

export const SET_DIALED_PBLOCK_GAMES = "SET_DIALED_PBLOCK_GAMES"
