import * as types from "../types"

export const setAuthCode = (authCode) => ({
  type: types.SET_AUTH_CODE,
  authCode
})
export const initUserWithToken = (token) => ({
  type: types.INIT_USER_WITH_TOKEN,
  token
})
export const initConfigWithoutAuth = () => ({
  type: types.INIT_CONFIG_WITHOUT_AUTH
})
export const initAsset = () => ({
  type: types.INIT_ASSET
})
export const initConfigWithAuth = () => ({
  type: types.INIT_CONFIG_WITH_AUTH
})
export const logoutAct = () => ({
  type: types.SET_LOG_OUT
})
