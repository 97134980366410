const STATUS_UNAUTHORIZED = 401

const DEFAULT_PRECISION = 2

const IS_FULL_LANG = false
const getLanguages = () => {
  const languages = [
    {
      value: "vi",
      label: "Tiếng Việt",
      show: true
    },
    {
      value: "en",
      label: "English",
      show: true
    },
    {
      value: "de",
      label: "Deutsch",
      show: IS_FULL_LANG
    },
    {
      value: "fr",
      label: "Française",
      show: IS_FULL_LANG
    },
    {
      value: "km",
      label: "ទង់ជាតិកម្ពុជា",
      show: IS_FULL_LANG
    },
    {
      value: "ko",
      label: "한국어",
      show: IS_FULL_LANG
    },
    {
      value: "lo",
      label: "ປະເທດລາວ",
      show: IS_FULL_LANG
    },
    {
      value: "ja",
      label: "日本語",
      show: IS_FULL_LANG
    },
    {
      value: "ms",
      label: "Bahasa Melayu",
      show: IS_FULL_LANG
    },

    {
      value: "th",
      label: "ไทย",
      show: IS_FULL_LANG
    },

    {
      value: "zh-CN",
      label: "中文",
      show: IS_FULL_LANG
    }
  ]
  return languages.filter(({ show }) => {
    return !!show
  })
}

const LANGUAGES = getLanguages()
const LANGUAGES_VALUES: string[] = LANGUAGES.map((i) => i.value)

const AUTH_AUTHENTICATED = "Authenticated"
const AUTH_UNAUTHENTICATED = "Unauthenticated"

const GROUP_POWER_BLOCK = "power_block"
const GROUP_NUMBER = "number"

const NAME_GAMES = {
  POWER_BLOCK_GAME: "power_block",
  POWER_GAME: "power_game",
  NUMBER_GAME: "number"
}

const STORAGE_KEY = {
  USER: "FXCE_USER"
}

const AXIOS_TIME_OUT = 60000

const IMG_DEFAULT = "/default/img-place-holder.webp"
const IMG_PLACE_HOLDER = "/default/placeholder-blur-19-6.webp"

export {
  STATUS_UNAUTHORIZED,
  DEFAULT_PRECISION,
  LANGUAGES,
  LANGUAGES_VALUES,
  AUTH_AUTHENTICATED,
  AUTH_UNAUTHENTICATED,
  GROUP_POWER_BLOCK,
  GROUP_NUMBER,
  NAME_GAMES,
  STORAGE_KEY,
  AXIOS_TIME_OUT,
  IMG_DEFAULT,
  IMG_PLACE_HOLDER
}
