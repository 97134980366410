import { values, toPairs, chunk } from "lodash"
import { getTimestamp, round } from "@helpers/utils"

const mapHistoryGame = (data = {}) => {
  const ballPower = values(data.ball_power)
  const ballBlock = values(data.ball_block)
  const balls = [...ballPower, ...ballBlock]

  return {
    ballBlock,
    ballPower,
    balls,
    createdAt: getTimestamp(data.created_at),
    createdAtTimestamp: getTimestamp(data.created_at_timestamp),
    gameLaunch: getTimestamp(data.game_launch),
    resultAt: getTimestamp(data.result_at),
    resultAtTimestamp: getTimestamp(data.result_at * 1000),
    gameType: data.game_type,
    id: data.id,
    remainConsecutive: data.remain_consecutive,
    panelAmount: round(data.panel_amount),
    prizeAmount: round(data.prize_amount),
    updatedAt: getTimestamp(data.updated_at)
  }
}

const mapHotColdNumbers = (data = {}) => {
  return {
    coldNumber: chunk(toPairs(data.cold_number), 3),
    hotNumbers: chunk(toPairs(data.hot_numbers), 3)
  }
}

const mapExistPanel = (data = {}) => {
  return {
    isOtherPurchased: data.is_other_purchased,
    isOwnerPurchased: data.is_owner_purchased
  }
}

export { mapHistoryGame, mapHotColdNumbers, mapExistPanel }
