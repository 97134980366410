import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { get, isNull, throttle } from "lodash"
import { toast } from "react-toastify"
import { i18n } from "next-i18next"
import { UtilCookies } from "@fxce/fe-core"
import { getIsClient, isSuccessRes } from "@helpers/utils"
import { store } from "@redux/store"
import { STATUS_UNAUTHORIZED } from "@config/constants"
import { postRefreshToken } from "@api/authAPI"
import { ResObject } from "@helpers/apiUtils"
import * as types from "@redux/types"
import { RefreshTokenType } from "@model/user.type"
import { initUserWithToken } from "@redux/actions/userActions"
import {
  IInterceptorError,
  IInterceptorRequest,
  IInterceptorResponse
} from "../axios.interface"

const tCommon = (text: string) => {
  const namespace = "common"
  if (i18n) {
    const string = `${namespace}:${text}`
    return i18n.t(string)
  }
  return text
}

let sendRequest: Promise<ResObject<RefreshTokenType>> = null
const excludeRoute401 = [
  // "/sign_in"
]
const routeRefreshToken = "/authentication/refresh_token"

// call at most once per 1s
const callbackRefreshTokenSuccess = throttle(
  (data) => {
    UtilCookies.setUserCookie(data)
    store.dispatch(initUserWithToken(data.accessToken))
  },
  1000,
  {
    leading: true,
    trailing: false
  }
)

const expiredTokenToast = () => {
  return new Promise((resolve) => {
    toast.error(tCommon("Your account has been expired"), {
      onOpen: () => {
        resolve(undefined)
      },
      toastId: "expired-toast",
      autoClose: 2000
    })
  })
}

const showRefreskTokenErrorToast = () => {
  const isActiveToast = toast.isActive("refreshToken_error")
  if (!isActiveToast) {
    toast.error(
      tCommon(
        "Something went wrong. Please refresh page or try again after some time"
      ),
      {
        toastId: "refreshToken_error",
        autoClose: 2000
      }
    )
  }
}

const handleAuthError = (error: AxiosError) => {
  const originalRequest = error.config as AxiosRequestConfig & {
    _retry?: boolean
  }

  const { status } = error.response
  const { refreshToken } = UtilCookies.getUserCookie()
  // handle error with api /refresh_token
  if (originalRequest.url.includes(routeRefreshToken)) {
    // if refreshToken fail
    const requestData = JSON.parse(originalRequest.data)
    if (requestData.refresh_token === refreshToken) {
      const shouldClearCookie = status >= 400 && status <= 499
      if (shouldClearCookie) {
        return expiredTokenToast().then(() => {
          store.dispatch({
            type: types.CLEAR_USER
          })
          return Promise.reject(error)
        })
      }
      showRefreskTokenErrorToast()
    }
    return Promise.reject(error)
  }

  const is401 = status === STATUS_UNAUTHORIZED
  const isClient = getIsClient()
  if (!is401 && isClient) {
    const { accessToken } = UtilCookies.getUserCookie()
    if (!accessToken) {
      store.dispatch({
        type: types.CLEAR_USER
      })
    }
    return Promise.reject(error)
  }
  if (is401 && isClient && !originalRequest._retry) {
    if (excludeRoute401.some((url) => originalRequest.url?.includes(url))) {
      return Promise.reject(error)
    }
    // refresh-token
    originalRequest._retry = true
    // handle first 401 api, other will watch this promise resolve
    sendRequest = !isNull(sendRequest)
      ? sendRequest
      : postRefreshToken(refreshToken)

    return sendRequest
      .then(({ data: res }) => {
        if (isSuccessRes(res)) {
          const data = get(res, "data", {})
          if ("accessToken" in data) {
            callbackRefreshTokenSuccess(data)
            originalRequest.headers.Authorization = data.accessToken
            return axios(originalRequest)
          }
          return Promise.reject(error)
        }
        return Promise.reject(error)
      })
      .finally(() => {
        sendRequest = null
      })
  }
  return Promise.reject(error)
}

export const requests: IInterceptorRequest[] = [
  (_config) => {
    const config = { ..._config }
    const { accessToken } = UtilCookies.getUserCookie()
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `${accessToken}`
      }
      if (!config.headers.Authorization) {
        delete config.headers.Authorization
      }
      if (!config.headers["X-API-TENANT"]) {
        delete config.headers["X-API-TENANT"]
      }
    }
    return config
  }
]

export const responses: IInterceptorResponse[] = [
  (response) => {
    return response
  }
]

export const errors: IInterceptorError[] = [(error) => handleAuthError(error)]
