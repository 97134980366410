import { combineReducers } from "redux"
import configReducer from "./configReducer"
import userReducer from "./userReducer"

const reducers = {
  rootConfig: configReducer,
  rootUser: userReducer
}

export default combineReducers(reducers)
