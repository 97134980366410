import getConfig from "next/config"
const { publicRuntimeConfig } = getConfig()

const env = {
  // common FXCE_ENV_FE
  // require for header app menu
  APP_ENV: publicRuntimeConfig.APP_ENV,
  SSO_URL: publicRuntimeConfig.SSO_URL,
  PBLOCK_URL: publicRuntimeConfig.PBLOCK_URL,
  HOME_URL: publicRuntimeConfig.HOME_URL,
  ARENA_URL: publicRuntimeConfig.ARENA_URL,
  BLOG_URL: publicRuntimeConfig.BLOG_URL,
  EA_URL: publicRuntimeConfig.EA_URL,
  CRYPTO_URL: publicRuntimeConfig.CRYPTO_URL,
  SUPPORT_CENTER_URL: publicRuntimeConfig.SUPPORT_CENTER_URL,
  W_CHART_URL: publicRuntimeConfig.W_CHART_URL,
  BACKTEST_INVEST_URL: publicRuntimeConfig.BACKTEST_INVEST_URL,
  STP_REFERRAL_URL: publicRuntimeConfig.REFERRAL_URL,
  LINK_FAQ: publicRuntimeConfig.FAQ_URL,
  LINK_FAQ_VI: publicRuntimeConfig.FAQ_URL,
  LINK_FAQ_EN: `${publicRuntimeConfig.FAQ_URL}/en`,

  IS_DEV: publicRuntimeConfig.APP_ENV === "development",
  IS_PROD: publicRuntimeConfig.APP_ENV === "production",
  SECRET_KEY_COOKIE: publicRuntimeConfig.SECRET_KEY_COOKIE,
  COOKIE_DOMAIN: publicRuntimeConfig.COOKIE_DOMAIN,
  DOMAIN: publicRuntimeConfig.DOMAIN,

  // individual env
  API_URL: publicRuntimeConfig.API_URL,

  BASE_META_URL: publicRuntimeConfig.PBLOCK_URL,
  DEFAULT_META_IMAGE: `${publicRuntimeConfig.PBLOCK_URL}/${publicRuntimeConfig.DEFAULT_META_IMAGE_PATH}`,

  SENTRY_DSN:
    publicRuntimeConfig.SENTRY_DSN ||
    publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN,
  // DOMAIN_URL: publicRuntimeConfig.DOMAIN_URL,
  // LINK_FAQ_VI: publicRuntimeConfig.LINK_FAQ_VI,
  // LINK_FAQ_EN: publicRuntimeConfig.LINK_FAQ_EN,
  // LINK_CLIENT_AGREEMENT: publicRuntimeConfig.LINK_CLIENT_AGREEMENT,
  // LINK_PROVISION: publicRuntimeConfig.LINK_PROVISION,

  LINK_MT5_WINDOW: publicRuntimeConfig.LINK_MT5_WINDOW,
  LINK_MT5_MACOS: publicRuntimeConfig.LINK_MT5_MACOS,
  LINK_MT5_ANDROID: publicRuntimeConfig.LINK_MT5_ANDROID,
  LINK_MT5_IOS: publicRuntimeConfig.LINK_MT5_IOS
}

export default env
