import { UtilCookies } from "@fxce/fe-core"
import env from "@config/env"
import { mapRefreshToken, mapUser } from "@model/user"
import { makeRequest, mapObject } from "@helpers/apiUtils"
const { API_URL } = env

const getMe = async (token: string) => {
  let headers = {}
  if (token) {
    headers = {
      Authorization: token
    }
  }
  const res = await makeRequest({
    method: "GET",
    url: `${API_URL}/api/authentication/me`,
    headers
  })
  return mapObject(res, mapUser)
}

const postRefreshToken = async (token?: string) => {
  const data = {
    refresh_token: token || UtilCookies.getUserCookie().refreshToken
  }
  const res = await makeRequest({
    method: "POST",
    url: `${API_URL}/api/authentication/refresh_token`,
    data
  })
  return mapObject(res, mapRefreshToken)
}

const postSignOut = async () => {
  const res = await makeRequest({
    method: "DELETE",
    url: `${API_URL}/api/authentication/sign_out`
  })
  return mapObject(res, mapUser)
}

const putLang = async (lang: string) => {
  const data = new FormData()
  lang = lang === "vi" ? "vi" : "en"
  const params = { lang }
  data.append("lang", lang)
  const res = await makeRequest({
    method: "PUT",
    url: `${API_URL}/api/users/language`,

    data,
    params
  })
  return mapObject(res, mapUser)
}

export { getMe, postRefreshToken, postSignOut, putLang }
