import { values, get } from "lodash"
import { getTimestamp, trunc } from "@helpers/utils"

const mapGame = (data = {}) => {
  const transactionInfo = data.blockchain_transaction_info || {}
  const hashPower = get(transactionInfo, "power", [])
  const hashBlock = get(transactionInfo, "block", [])

  const ballBlock = values(data.ball_block)
  const ballPower = values(data.ball_power)
  const hashNumbers = [...hashPower, ...hashBlock]

  return {
    id: data.id,
    roomId: data.room_id,
    numberId: data.number_id,

    transactionInfo,

    totalPanel: trunc(data.total_panel),
    totalPanelAmount: trunc(data.total_panel_amount),
    totalPrizeAmount: trunc(data.total_prize_amount),

    addingJackpot: !!data.adding_jackpot,
    status: data.status,

    createdAtTimestamp: data.created_at_timestamp * 1000,
    updatedAtTimestamp: data.updated_at_timestamp * 1000,
    resultAtTimestamp: data.result_at_timestamp * 1000,
    endBetAtTimestamp: getTimestamp(data.end_bet_at),
    // string time
    endBetAt: getTimestamp(data.end_bet_at),
    startBetAt: getTimestamp(data.start_bet_at),
    resultAt: getTimestamp(data.result_at),

    endAt: getTimestamp(data.end_at),

    ballPower,
    ballBlock,
    numbers: [...ballPower, ...ballBlock],
    ballCompare: data.ball_compare,
    hashNumbers
  }
}

const mapBetPlace = (data = {}) => {
  return {
    id: data.id,
    roomId: data.room_id,
    title: data.title,
    groupTitle: data.group_title,

    numberBallPower: trunc(data.number_ball_power),
    minBallPower: trunc(data.min_ball_power),
    maxBallPower: trunc(data.max_ball_power),

    numberBallBlock: trunc(data.number_ball_block),
    minBallBlock: trunc(data.min_ball_block),
    maxBallBlock: trunc(data.max_ball_block),

    minAmount: trunc(data.min_amount),
    fixPanelAmount: trunc(data.fix_panel_amount),
    maxTotalPanelAmount: trunc(data.max_total_panel_amount),

    addingJackpot: trunc(data.adding_jackpot),
    active: data.active,

    // endAt: data.end_at,
    // endBetAt: data.end_bet_at,

    endAt: getTimestamp(data.end_at),
    endBetAt: getTimestamp(data.end_bet_at),

    createdAtTimestamp: getTimestamp(data.created_at_timestamp * 1000),
    updatedAtTimestamp: getTimestamp(data.updated_at_timestamp * 1000)
  }
}

export { mapGame, mapBetPlace }
