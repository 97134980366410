import {
  AdvancedSearchType,
  createFilters,
  makeRequest,
  makeRequestNoAuth,
  mapList,
  mapObject
} from "@helpers/apiUtils"
import env from "@config/env"
import {
  mapHistoryGame,
  mapHotColdNumbers,
  mapExistPanel
} from "@model/powerBlock"
import { mapGame } from "@model/game"

import { getUTCEndOfDay, getUTCStartOfDay } from "@helpers/utils"
const { API_URL } = env

const getJackpot = async () => {
  const res = await makeRequestNoAuth({
    method: "GET",
    url: `${API_URL}/pblock/jackpots`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-VERSION": "v1"
    }
  })
  return res
}

const getDialed = async () => {
  const res = await makeRequestNoAuth({
    method: "GET",
    url: `${API_URL}/pblock/games/dialed`
  })

  return mapList(res, mapGame)
}
const getResultDrawAPI = async (id) => {
  const res = await makeRequest({
    method: "GET",
    url: `${API_URL}/pblock/games/${id}`
  })

  return mapObject(res, mapGame)
}

const getHotColdNumber = async () => {
  const res = await makeRequestNoAuth({
    method: "GET",
    url: `${API_URL}/pblock/games/hot_cold_number`
  })
  return mapObject(res, mapHotColdNumbers)
}

const getPBlockGames = async ({ status }: AdvancedSearchType = {}) => {
  const params = createFilters(
    {},
    {
      status
    }
  )
  const res = await makeRequestNoAuth({
    method: "GET",
    url: `${API_URL}/pblock/games`,
    params
  })

  return mapList(res, mapGame)
}

const postPanel = async ({ id, data }) => {
  const res = await makeRequest({
    method: "POST",
    url: `${API_URL}/pblock/games/${id}/buy_panels`,
    data: { data }
  })
  return res
}
const getHistoryTicket = async ({
  status,
  page = 1,
  per = 20,
  fromDate,
  toDate,
  gameId,
  groupTitle
}) => {
  let params: any = { per, page }
  if (fromDate) {
    params.start_at = getUTCStartOfDay(fromDate) / 1000
  }
  if (toDate) {
    params.end_at = getUTCEndOfDay(toDate) / 1000
  }
  if (groupTitle) {
    params.group_title = groupTitle
  }
  if (gameId && gameId !== "All") {
    params.game_id = gameId
  }
  params = createFilters(params, {
    status
  })

  const res = await makeRequest({
    method: "GET",
    url: `${API_URL}/pblock/panels/purchase_history`,
    params
  })

  return mapList(res, mapHistoryGame)
}

const getDealingAPI = async () => {
  const res = await makeRequest({
    method: "GET",
    url: `${API_URL}/pblock/games/dialing`
  })

  return mapObject(res, mapHistoryGame)
}

const checkExistPanel = async ({ gameId, payload }) => {
  const data = [
    {
      bet_place_id: payload.bet_place_id,
      ball_power: payload.ball_power,
      ball_block: payload.ball_block
    }
  ]
  const res = await makeRequestNoAuth({
    method: "POST",
    url: `${API_URL}/pblock/games/${gameId}/check_exist_panel`,
    data: { data }
  })
  return mapObject(res, mapExistPanel)
}

export {
  getJackpot,
  postPanel,
  getHistoryTicket,
  getDialed,
  getHotColdNumber,
  getPBlockGames,
  checkExistPanel,
  getResultDrawAPI,
  getDealingAPI
}
