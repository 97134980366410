import { get, first, filter } from "lodash"
import { call, put, select, takeLatest, delay } from "redux-saga/effects"
import { getBetPlaces } from "@api/gameAPI"
import { getDialed, getPBlockGames } from "@api/powerBlockAPI"

import { GROUP_NUMBER, GROUP_POWER_BLOCK } from "@config/constants"
import * as types from "../types"

const TIMEOUT_TIME = 10 * 1000

const findBetPlaces = (resBetPlaces, type) => {
  return (
    filter(get(resBetPlaces, "data.data.items", []), { groupTitle: type }) || []
  )
}

function* initGame() {
  const resGames = yield call(getPBlockGames, { status: "process" })
  const games = get(resGames, "data.data.items", [])
  const game = first(games) || {}

  const resBetPlaces = yield call(getBetPlaces, game.id)
  const powerBlockBetPlaces = findBetPlaces(resBetPlaces, GROUP_POWER_BLOCK)
  const numberBetPlaces = findBetPlaces(resBetPlaces, GROUP_NUMBER)

  yield put({
    type: types.CONFIG_GAME,
    payload: {
      game,
      powerBlockBetPlaces,
      numberBetPlaces
    }
  })
}

function* initDialedGames() {
  const resGames = yield call(getDialed)
  const dialedGames = get(resGames, "data.data.items", [])

  yield put({
    type: types.SET_DIALED_PBLOCK_GAMES,
    payload: {
      dialedGames
    }
  })
}

function* initAutoBetPlace() {
  const state = yield select()
  const game = get(state, "rootConfig.game", {})
  const currentTime = new Date().getTime()
  const isExpired =
    !game.resultAtTimestamp || // empty
    game.resultAtTimestamp < currentTime // or resultAtTimestamp < currentTime
  if (isExpired) {
    yield call(initGame)
    yield call(initDialedGames)
  }
}

function* runAutoBetPlaces() {
  while (true) {
    yield delay(TIMEOUT_TIME)
    yield call(initAutoBetPlace)
  }
}

function* initConfigWithoutAuth() {
  yield call(initGame)
  yield call(initDialedGames)
  yield call(runAutoBetPlaces)
}

export function* configSagas() {
  yield takeLatest(types.INIT_CONFIG_WITHOUT_AUTH, initConfigWithoutAuth)
}
