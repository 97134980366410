import { format as formatDate } from "date-fns"
import { enUS, vi } from "date-fns/locale"
import { floor as _floor, round as _round } from "lodash"
import moment from "moment"
import queryString from "query-string"

const DEFAULT_PRECISION = 2

const isSuccessRes = (res: { status?: string | number } = {}) => {
  return (
    res.status === "success" ||
    (Number(res.status) >= 200 && Number(res.status) <= 299)
  )
}

const getIsClient = () => typeof window !== undefined

const floor = (
  number: number | string,
  precision = DEFAULT_PRECISION,
  hasZero = false
): number | string => {
  let value = number
  // fix bug for number 0.089999
  if (value && typeof value === "number" && value.toFixed) {
    value = value.toFixed(8)
  }
  const data = _floor(+value, precision)
  if (!hasZero) {
    return data
  }
  return data.toFixed(precision)
}

const trunc = (
  number: string | number,
  precision = DEFAULT_PRECISION
): number => {
  if (!number) {
    return 0
  }
  const re = new RegExp(`(\\d+\\.\\d{${precision}})(\\d)`)
  const m = number.toString().match(re)
  const result = m ? parseFloat(m[1]) : number.valueOf()
  if (number < 0) {
    return _floor(-result, precision)
  }
  return _floor(+result, precision)
}

const formatUTCTime = (time: string | number, format = "DD/MM/yyyy") => {
  return moment.utc(time).format(format)
}

const formatTime = (time: string | number, format = "DD/MM/yyyy") => {
  return moment(time).format(format)
}
// begin NUMBER_UTILS
const round = (
  number: number,
  precision = DEFAULT_PRECISION,
  hasZero = false
) => {
  const data = _round(number, precision)
  if (!hasZero) {
    return data
  }
  return data.toFixed(precision)
}

const formatCommas = (value: any) => {
  let x = value

  if (!x) {
    return x
  }
  if (x && x.toFixed && x.toString().indexOf("e") > -1) {
    x = x.toFixed(8)
  }
  const parts = x.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  return parts.join(".")
}

const formatBigNumber = (number: any, precision = DEFAULT_PRECISION) => {
  let value = Number(number)
  const absValue = Math.abs(value)
  let bigFormat = ""

  if (absValue >= 1e10) {
    bigFormat = "B"
    value /= 1e9
  } else if (absValue >= 1e7) {
    bigFormat = "M"
    value /= 1e6
  } else if (absValue >= 1e5) {
    bigFormat = "K"
    value /= 1000
  }

  if (Math.abs(value) > 10000) {
    precision = 0
  }

  return formatCommas(floor(value, precision)) + bigFormat
}

const formatMoney = (value: any, formatBig = true): string => {
  value = Number(value)
  const absValue = Math.abs(value)
  const preFix = value < 0 ? "-$" : "$"
  return (
    preFix +
    (formatBig ? formatBigNumber(absValue) : formatCommas(floor(absValue)))
  )
}

const createUuid = (): string => {
  let dt = new Date().getTime()
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    // eslint-disable-next-line no-bitwise
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

const getTimezone = () => {
  const timezone = floor(moment().utcOffset() / 60)
  // if (isNumber(localStore.get("FXCE_TIMEZONE"))) {
  //   return localStore.get("FXCE_TIMEZONE")
  // }
  return timezone
}

const getUTCStartOfDay = (date) => {
  const start = moment(date)
  start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  const longTime = start.valueOf()
  return longTime
  // return Number(moment(date).startOf("day"));
}

const getUTCEndOfDay = (date) => {
  const end = moment(date).add(getTimezone(), "hours")
  end.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
  const longTime = end.valueOf()
  return longTime
}

const addTimezone = (time: number | Date | string) => {
  // tz is number
  if (!time) {
    return time
  }

  return moment.utc(time).add(getTimezone(), "hours").valueOf()
}

const getFormatDate = (
  timestamps: Date | number,
  locale: string,
  _formatTime: string
) => {
  let format: string
  let lang: object = {}

  switch (locale) {
    case "vi":
      format = "EEEE dd/MM/yyyy"
      lang = vi
      break

    default:
      format = "MMMM dd, yyyy"
      lang = enUS
      break
  }
  if (_formatTime) {
    format = _formatTime
  }
  return formatDate(timestamps, format, { locale: lang })
}
const getTimestamp = (time: Date | number) => {
  return moment(time).valueOf()
}

const getParams = () => {
  const params = queryString.parse(window.location.search) || {}
  return params
}

export {
  addTimezone,
  createUuid,
  floor,
  formatBigNumber,
  formatCommas,
  formatMoney,
  formatTime,
  formatUTCTime,
  getFormatDate,
  getIsClient,
  getParams,
  getTimestamp,
  getTimezone,
  getUTCEndOfDay,
  getUTCStartOfDay,
  isSuccessRes,
  round,
  trunc
}
