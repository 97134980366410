import dynamic from "next/dynamic"

const TakeTokenNoSSR = dynamic(
  () => import("@components/auth/TakeToken/TakeToken"),
  {
    ssr: false
  }
)

export default TakeTokenNoSSR
