import { get } from "lodash"
import { RefreshTokenType, UserType } from "./user.type"

const getImage = (data = {}) => {
  return get(data, "image.thumb.url") || ""
}

const getLevel = (data) => {
  if (get(data, "user_kyc.step", "") === "lv2_approved") {
    return 3
  }
  if (data.level === 1 && data.first_name) {
    return 2
  }

  return data.level
}

const mapUser = (data: Record<string, any> = {}): UserType => {
  return {
    accessToken: data.access_token,
    accessTokenExpiryTime: data.access_token_expiry_time * 1000,
    antiphishingCode: data.anti_phishing_code,
    email: data.email,
    id: data.id,
    image: getImage(data),
    investorAccountIds: data.investor_account_ids || [],
    level: getLevel(data),
    refreshToken: data.refresh_token,
    status: data.status,
    tradingAccountIds: data.trading_account_ids || [],
    twoFaEnabled: !!data.two_fa_enabled,
    username: data.username,
    language: data.language,
    iBEnabled: !data.ib_enabled,
    address: data.address,
    birthday: data.birthday,
    firstName: data.first_name,
    lastName: data.last_name,
    gender: data.gender,
    nationality: data.nationality,
    phone: data.phone
  }
}

const mapRefreshToken = (data: Record<string, any>): RefreshTokenType => {
  const accessTokenExpiryTime = data.access_token_expiry_time * 1000
  const refreshTokenExpiryTime = data.refresh_token_expiry_time * 1000
  return {
    accessToken: data.access_token,
    accessTokenExpiryTime,
    refreshToken: data.refresh_token,
    refreshTokenExpiryTime
  }
}

export { mapUser, mapRefreshToken }
