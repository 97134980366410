import { all, fork } from "redux-saga/effects"

import * as userSagas from "./userSagas"
import * as configSagas from "./configSagas"

export default function* rootSaga() {
  yield all(
    [...Object.values(userSagas), ...Object.values(configSagas)].map(fork)
  )
}
