/* eslint-disable react/jsx-no-useless-fragment */
import { memo, useCallback, useEffect } from "react"

const SCRIPT_URL = "https://static-assets.fxce.net/js/fxce-v2.js"
const SCRIPT_ID = "fxce-v2-js"

const ThirdPartyJS = memo(() => {
  const removeJS = useCallback(() => {
    const scriptElement = document.getElementById(SCRIPT_ID)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    scriptElement && scriptElement?.remove()
  }, [])

  useEffect(() => {
    const scriptElement = document.getElementById(SCRIPT_ID)
    const today = new Date()
    const dateString = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`

    if (!scriptElement) {
      const script = document.createElement("script")
      script.src = `${SCRIPT_URL}?time=${dateString}` // https://static-assets.fxce.net/js/fxce.js?time=2023-10-11
      script.id = SCRIPT_ID
      script.async = true
      document.body.appendChild(script)
    }
    return () => {
      removeJS()
    }
  }, [removeJS])

  return <></>
})
export default ThirdPartyJS
