import axios, { AxiosResponse, AxiosRequestConfig } from "axios"

import Promise from "promise"

import { get, set, isEmpty, forEach, merge, isArray, cloneDeep } from "lodash"
import axiosNoAuth from "./axios/noAuth"

// eslint-disable-next-line no-unused-vars
type CallbackFunction = (item: object) => void

export type PaginationType = {
  current_page?: number
  limit_value?: number
  next_page?: number
  prev_page?: number
  total_pages?: number
}
export type AdvancedSearchType = {
  per?: number
  page?: number
  status?: string
  type?: string
  sort_field?: string
  direction?: string
  username?: string
  q?: string
  toDate?: string
  fromDate?: string
  id?: string
}

export type ResObject<T> = AxiosResponse<{
  status?: number
  message: string
  errors?: object
  data: T
}>

export type ResList<T> = AxiosResponse<{
  status?: number
  message: string
  errors?: object
  data: {
    items: Array<T>
    pagination: PaginationType
  }
}>

const createPromise = (
  axiosData: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return new Promise((resolve) => {
    return axios(axiosData)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}

const createPromiseNoAuth = (
  axiosData: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return new Promise((resolve) => {
    return axiosNoAuth(axiosData)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}

export const makeRequestNoAuth = ({
  method = "GET",
  url,
  data,
  headers = {},
  params = {}
}: AxiosRequestConfig): Promise<AxiosResponse> => {
  const options = {
    method,
    url,
    data,
    headers,
    params
  }
  // console.log("headers ", headers);
  return createPromiseNoAuth(options)
}

export const makeRequest = ({
  method = "GET",
  url,
  data,
  headers = {},
  params = {}
}: // }) => {
AxiosRequestConfig): Promise<AxiosResponse> => {
  const options = {
    method,
    url,
    data,
    headers,
    params
  }
  // console.log("headers ", headers);
  return createPromise(options)
}

export const mapList = (
  res: AxiosResponse,
  mapFunc: CallbackFunction
): AxiosResponse => {
  // const items = );
  // 2 node data.data.items or data.data will set to data.data.items
  // get(res, "data.data", []
  const items = merge(
    [],
    get(res, "data.data.items", get(res, "data.data", []))
  )
  const result = items.map(mapFunc)
  set(res, "data.data.items", result)
  return res
}

export const mapObject = (
  res: AxiosResponse,
  mapFunc: CallbackFunction
): AxiosResponse => {
  const item = merge({}, get(res, "data.data", {}))
  const result = mapFunc(item)
  set(res, "data.data", result)
  return res
}

// data is [] but want to get 0
export const mapArrayToObject = (
  res: AxiosResponse,
  mapFunc: CallbackFunction
): AxiosResponse => {
  const item = cloneDeep(get(res, "data.data[0]", {}))
  const result = mapFunc(item)
  set(res, "data.data", result)
  return res
}

export const createFilters = (params = {}, filters: object) => {
  if (!isEmpty(filters)) {
    forEach(filters, (value: string, key: string) => {
      if (isArray(value)) {
        params[`filters[${key}]`] = JSON.stringify(value)
        return
      }
      if (value) {
        params[`filters[${key}]`] = value
      }
    })
  }
  return params
}
