import { applyMiddleware, createStore } from "redux"
import { createWrapper } from "next-redux-wrapper"
import createSagaMiddleware from "redux-saga"

import rootSaga from "@redux/sagas"
import rootReducer from "./reducers/_index"

const isDebug = process.env.NODE_ENV !== "production"

const bindMiddleware = (middleware) => {
  if (isDebug) {
    // eslint-disable-next-line
    const { composeWithDevTools } = require("redux-devtools-extension")
    // eslint-disable-next-line
    const { createLogger } = require("redux-logger")
    const logger = createLogger({})
    return composeWithDevTools(applyMiddleware(...middleware, logger))
  }
  return applyMiddleware(...middleware)
}

const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]))
store.sagaTask = sagaMiddleware.run(rootSaga)

export const makeStore = () => {
  return store
}

const wrapper = createWrapper(makeStore, { debug: false })

export { wrapper, store }
