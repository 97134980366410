// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

import env from "@config/env"
const { APP_ENV, SENTRY_DSN } = env

if (SENTRY_DSN) {
  Sentry.init({
    environment: APP_ENV,
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0
  })
}
