import { UtilCookies } from "@fxce/fe-core"
import { AUTH_AUTHENTICATED, AUTH_UNAUTHENTICATED } from "@config/constants"
import * as types from "../types"

// INITIAL TIMER STATE
const initialState = {
  authCode: "", // AUTH_AUTHENTICATED, AUTH_UNAUTHENTICATED
  user: {},
  assets: [], // asset of user
  objAssets: {}, // object of asset
  pBlockAsset: {} // asset of pb
}

// TIMER REDUCER
const userReducer = (state = initialState, { type, payload }) => {
  const user = { ...payload }
  switch (type) {
    case types.SET_USER:
      return {
        ...state,
        user,
        authCode: user.id ? AUTH_AUTHENTICATED : AUTH_UNAUTHENTICATED
      }

    case types.CLEAR_USER:
      UtilCookies.removeUserCookie()
      UtilCookies.removeCookie("FXCE_SESSION_ID")
      return {
        ...state,
        user: {},
        authCode: AUTH_UNAUTHENTICATED,
        assets: [],
        objAssets: {},
        pBlockAsset: {}
      }

    case types.SET_AUTH_CODE:
      return {
        ...state,
        authCode: payload
      }

    case types.SET_ASSET:
      return {
        ...state,
        assets: payload.assets,
        objAssets: payload.objAssets,
        pBlockAsset: payload.pBlockAsset
      }

    default:
      return state
  }
}

export default userReducer
