import axios from "axios"
import { AXIOS_TIME_OUT } from "src/config/constants"
import axiosNoAuth from "./noAuth"
import "./interceptors"

axios.defaults.timeout = AXIOS_TIME_OUT
axios.defaults.headers["X-API-VERSION"] = "v1"
axios.defaults.headers["content-type"] = "application/json"
axios.defaults.headers.Accept = "application/json"

axiosNoAuth.defaults.timeout = AXIOS_TIME_OUT
axiosNoAuth.defaults.headers["X-API-VERSION"] = "v1"
axiosNoAuth.defaults.headers["content-type"] = "application/json"
axiosNoAuth.defaults.headers.Accept = "application/json"
