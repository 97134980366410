import * as types from "../types"

// INITIAL TIMER STATE
const initialState = {
  // user: {},
  game: {},
  powerBlockBetPlaces: [],
  numberBetPlaces: [],
  dialedGames: []
}

// TIMER REDUCER
const configReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CONFIG_GAME:
      return {
        ...state,
        game: payload.game,
        powerBlockBetPlaces: payload.powerBlockBetPlaces,
        numberBetPlaces: payload.numberBetPlaces
      }

    case types.SET_DIALED_PBLOCK_GAMES:
      return {
        ...state,
        dialedGames: payload.dialedGames
      }
    default:
      return state
  }
}

export default configReducer
