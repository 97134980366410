import axios from "axios"
import axiosNoAuth from "../noAuth"
import * as LanguageInterceptor from "./axios.interceptor.lang"
import * as AuthorizationInterceptor from "./axios.interceptor.authorization"
import * as MessageInterceptor from "./axios.interceptor.message"
import {
  IInterceptorError,
  IInterceptorRequest,
  IInterceptorResponse
} from "../axios.interface"

const interceptors = [
  LanguageInterceptor, // check and add language param to request
  AuthorizationInterceptor, // check and add Authorization to request's header
  MessageInterceptor // handle message
]

const requestHooks: IInterceptorRequest[] = []
const responseHooks: IInterceptorResponse[] = []
const errorHooks: IInterceptorError[] = []

for (const interceptor of interceptors) {
  requestHooks.push(
    ...(Array.isArray(interceptor.requests) ? interceptor.requests : [])
  )
  responseHooks.push(
    ...(Array.isArray(interceptor.responses) ? interceptor.responses : [])
  )
  errorHooks.push(
    ...(Array.isArray(interceptor.errors) ? interceptor.errors : [])
  )
}

/**
 * Before request interceptor
 */
for (const hook of requestHooks.reverse()) {
  axios.interceptors.request.use(hook)
  axiosNoAuth.interceptors.request.use(hook)
}

/**
 * Before response interceptor
 */
for (const hook of responseHooks.reverse()) {
  axios.interceptors.response.use(hook)
  axiosNoAuth.interceptors.response.use(hook)
}

for (const hook of errorHooks) {
  axios.interceptors.response.use(undefined, hook)
}

const noAuthError = [
  ...LanguageInterceptor.errors,
  ...MessageInterceptor.errors
  // remove error unauthentication
]
for (const errorNoAuth of noAuthError) {
  axiosNoAuth.interceptors.response.use(undefined, errorNoAuth)
}
